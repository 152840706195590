<template>
  <div>
    <div class="card-toolbar mb-5">
      <router-link v-if="$can('pos_session.create')" class="btn btn-primary font-weight-bolder"
                   to="/pos/pos-session/create">
        <v-icon>mdi-plus</v-icon>
        {{ $t('pos_session.new_pos_session') }}
      </router-link>
      <button class="btn btn-outline-primary font-weight-bolder ml-5" @click="openAdvancedSearch">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div v-if="showAdvancedSearch" class="card card-custom mb-5">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="device_id">{{ $t('pos_session.pos_device') }}</label>
              <!--                            <select name="device_id" id="device_id" v-model="filters.device_id" class="custom-select">-->
              <!--                                <option v-for="row in pos_device" :value="row.id" :key="row.id">{{row.name}}</option>-->
              <!--                            </select>-->
              <multiselect id="device_id"
                           v-model="device"
                           :multiple="false"
                           :options="pos_device"
                           :placeholder="$t('pos_session.pos_device')"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           :taggable="false"
                           label="name"
                           track-by="id">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="shift_id">{{ $t('pos_session.pos_shift') }}</label>
              <!--                            <select name="shift_id" id="shift_id" v-model="filters.shift_id" class="custom-select">-->
              <!--                                <option v-for="row in pos_shift" :value="row.id" :key="row.id">{{row.name}}</option>-->
              <!--                            </select>-->
              <multiselect id="shift_id"
                           v-model="shift"
                           :multiple="false"
                           :options="pos_shift"
                           :placeholder="$t('pos_session.pos_shift')"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           :taggable="false"
                           label="name"
                           track-by="id">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="opened_from_date">{{ $t('pos_session.opened_from_date') }}</label>
              <input id="opened_from_date" v-model="filters.opened_from_date" class="form-control" type="date">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="opened_to_date">{{ $t('pos_session.opened_to_date') }}</label>
              <input id="opened_to_date" v-model="filters.opened_to_date" class="form-control" type="date">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="closed_from_date">{{ $t('pos_session.closed_from_date') }}</label>
              <input id="closed_from_date" v-model="filters.closed_from_date" class="form-control" type="date">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="closed_to_date">{{ $t('pos_session.closed_to_date') }}</label>
              <input id="closed_to_date" v-model="filters.closed_to_date" class="form-control" type="date">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">
                {{ $t('status') }}
              </label>
              <select id="f_status" v-model="filters.status" class="custom-select" name="" type="text">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :key="index" :value="row.id">{{ row.title }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i>
                {{ $t('search') }}
              </b-button>
              <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i>
                {{ $t('reset_search') }}
              </b-button>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <div>
          <div class="row justify-content-end p-4">
            <custom-export-data v-if="$can('pos_session.export_data')" :data-list="dataList" :fields="json_fields"
                                :file-name="$t('MENU.pos_sessions')"></custom-export-data>
          </div>
        </div>

        <v-server-table ref="table" :class="'dataTable table-row-dashed'" :columns="columns" :options="options">

          <template slot="shift_cashier" slot-scope="props">
            <div>
              <span>{{ props.row.shift_name }}</span>
            </div>
            <div>
              <span>{{ props.row.added_by }}</span>
            </div>
          </template>
          <template slot="opening_closing" slot-scope="props">
            <div>
              <span>{{ props.row.opened_date }}</span>
            </div>
            <div>
              <span>{{ props.row.closed_date }}</span>
            </div>
          </template>

          <!-- <template slot="status" slot-scope="props">
              <b-form-checkbox v-if="$can('pos_session.update')" size="lg" @change="changeStatus(props.row.id, props.row.status)" v-model="props.row.status" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
          </template> -->
          <template slot="status" slot-scope="props">
            <button v-if="$can('pos_session.change_status')" :disabled='props.row.isDisabled'
                    class="btn btn-secondary m-btn m-btn--icon w-auto"
                    @click="changeStatus(props.row.id, props.row.status, props.row.session_id)">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>
            <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>

          </template>
          <template slot="actions" slot-scope="props">
            <!--                        <v-icon small  color="blue darken-2" class="mr-2" @click="editItem(props.row)">mdi-pencil</v-icon>-->
            <v-btn v-if="$can('pos_session.details')" v-b-tooltip.hover :title="$t('view')"
                   :to="`/pos/pos-session/view/${props.row.id}`" color="pink"
                   icon>
              <v-icon class="mr-2" small>mdi-eye</v-icon>
            </v-btn>
            <!--                      v-if="$can('pos_session.cash_closing')"-->

            <v-icon v-if="$can('pos_session.delete')" v-b-tooltip.hover :title="$t('delete')" class="text-danger"
                    color="red darken-2"
                    small @click="deleteItem(props.row)">mdi-delete
            </v-icon>
            <!--                      <router-link  v-if="props.row.status == 1" class="btn btn-primary m-btn m-btn&#45;&#45;icon w-auto" :to="`/pos/cash-closing/${props.row.id}`">-->
            <!--                        {{$t('MENU.cash_closing')}}-->
            <!--                      </router-link>-->

            <b-dropdown v-if="(props.row.status == 1 || props.row.status == 2)" :id="'dropdown-offset-'+props.row.id"
                        :text="$t('more_actions')" class="m-2" variant="outline-primary">
              <b-dropdown-item v-if="$can('point_sales.list') && props.row.status == 1"
                               :href="'/point-of-sales/point-of-sales'" target="_blank">
                {{ $t('MENU.sales_screen') }}
              </b-dropdown-item>
              <b-dropdown-item v-if="$can('cash_movement.list') && props.row.status == 1"
                               :href="'/pos/cash-movement/'+props.row.id" target="_blank">
                {{ $t('MENU.cash_movement') }}
              </b-dropdown-item>
              <b-dropdown-item v-if="$can('pos_session.session_validate') && props.row.status == 2" href="javascript:;"
                               @click="sessionValidate(props.row.id, props.row.status, props.row.session_id)">
                {{ $t('session_validate') }}
              </b-dropdown-item>
              <b-dropdown-item v-if="$can('pos_session.cash_closing') && props.row.status == 1"
                               :to="`/pos/cash-closing/${props.row.id}`" target="_blank">
                {{ $t('MENU.cash_closing') }}
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('print_procedures') " variant="outline-primary btn-sm" class="m-2">
              <template>
                <div class="dropdown-item d-flex justify-content-between" v-if="$can('pos_session.printing_export_pdf')">
                  <label>{{ $t('print_session') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/pos/session-details/${props.row.id}/print`">
                      <i class="fas fa-print"></i>
                    </router-link>
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/pos/session-details/${props.row.id}/pdf`">
                      <i class="fas fa-file-pdf"></i>
                    </router-link>
                  </div>
                </div>
                <div class="dropdown-item d-flex justify-content-between" v-if="$can('pos_session.printing_export_pdf')">
                  <label>{{ $t('print_80ml') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/pos/session-details-80mm/${props.row.id}/print`">
                      <i class="fas fa-print"></i>
                    </router-link>
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/pos/session-details-80mm/${props.row.id}/pdf`">
                      <i class="fas fa-file-pdf"></i>
                    </router-link>
                  </div>
                </div>
              </template>
            </b-dropdown>
          </template>
        </v-server-table>


        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->
    <b-modal ref="status-modal" :title="$t('change_status')" hide-footer size="md">
      <change-status-form :current-id="innerId"
                          :current-status="statusId"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          @hide-modal="hideModal()"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>
    <b-modal ref="validate-session-modal" :title="$t('session_validate')" hide-footer size="xl">
      <pos-validate-session-form :current-id="innerId"
                                 :current-session-id="sessionId"
                                 :current-status="statusId"
                                 @hide-modal="hideValidateSessionModal()"
                                 @handling-data="getDataAfterValidateSession">
      </pos-validate-session-form>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";
import PosValidateSessionForm from "@/view/content/forms/PosValidateSessionForm";

export default {
  name: "index-pos-session",
  components: {'change-status-form': ChangeStatusFrom, 'pos-validate-session-form': PosValidateSessionForm,},
  data() {
    return {
      mainRoute: 'pos/pos-sessions',
      subMainRoute: 'pos/pos-session',
      mainRouteDependency: 'base/dependency',
      routeChangeStatus: 'pos/pos-session/change-status',
      showAdvancedSearch: false,
      dataList: [],
      filters: {
        device_id: '',
        shift_id: '',
        opened_from_date: '',
        opened_to_date: '',
        closed_from_date: '',
        closed_to_date: '',
        status: '',
      },
      inventory: null,
      innerId: null,
      statusId: null,
      sessionId: null,
      status_list: [],
      categories: [],
      pos_shift: [],
      pos_device: [],

      columns: ['session_name', 'shift_cashier', 'opening_closing', 'session_sales_amount', 'status', 'actions'],
      device: null,
      shift: null,
    }
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('pos_session.name')] = 'session_name';
      fields[this.$t('pos_session.cashier')] = 'shift_cashier';
      fields[this.$t('pos_session.opening_closing')] = 'opening_closing';
      fields[this.$t('pos_session.session_sales_amount')] = 'session_sales_amount';

      return fields;
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          session_name: that.$t('pos_session.name'),
          shift_cashier: that.$t('pos_session.cashier'),
          opening_closing: that.$t('pos_session.opening_closing'),
          session_sales_amount: that.$t('pos_session.session_sales_amount'),
          status: that.$t('status'),
          actions: that.$t('actions'),

        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        },

      }

    },
  },
  watch: {
    device: function (val) {
      if (val) {
        this.filters.device_id = val.id;
      } else {
        this.filters.device_id = null;
      }
    },
    shift: function (val) {
      if (val) {
        this.filters.shift_id = val.id;
      } else {
        this.filters.shift_id = null;
      }
    },
  },
  mounted() {
    // let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.pos_sessions")}]);
    this.getCategories();
    this.getStatusList();
  },
  methods: {
    getShiftsAndDevices() {
      ApiService.get(this.mainRouteDependency + "/pos_shifts_and_devices").then((response) => {
        this.pos_shift = response.data.data.pos_shift;
        this.pos_device = response.data.data.pos_device;
      });
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/session_status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    showModal() {
      this.$refs['status-modal'].show()
    },
    hideModal() {
      this.$refs['status-modal'].hide();
    },
    showValidateSessionModal() {
      this.$refs['validate-session-modal'].show()
    },
    hideValidateSessionModal() {
      this.$refs['validate-session-modal'].hide();
    },
    changeStatus(id, status, sessionId) {
      this.innerId = id;
      this.statusId = status;
      this.sessionId = sessionId;
      this.showModal();
    },
    sessionValidate(id, status, sessionId) {
      this.innerId = id;
      this.statusId = status;
      this.sessionId = sessionId;
      this.showValidateSessionModal();
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.sessionId = null;
      this.doFilter();
    },

    getDataAfterValidateSession() {
      this.innerId = null;
      this.statusId = null;
      this.sessionId = null;
      this.doFilter();
    },

    getFetch() {
      this.$refs.table.refresh();
    },
    openAdvancedSearch() {
      if (this.pos_shift.length === 0 || this.pos_device.length === 0) {
        this.getShiftsAndDevices();
      }
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.device_id = '';
      this.filters.shift_id = '';
      this.filters.opened_from_date = '';
      this.filters.opened_to_date = '';
      this.filters.closed_from_date = '';
      this.filters.closed_to_date = '';
      this.filters.status = '';
      this.device = null;
      this.shift = null;

      this.$refs.table.refresh();
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    editItem(item) {
      this.$router.push({name: 'pos-session.edit', params: {id: item.id}});
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },

    loadOptions() {
    },
    updateValue(value) {
      this.filters.parent_category = value
    },
  },
};
</script>
